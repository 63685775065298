import axios from "axios";
import { baseUrl } from "../config";
import { Product,ProductId } from "../types/product";

const cache: {
  [key: ProductId]: Promise<Product>;
} = {};

export const getOneById = (id: ProductId): Promise<Product> => {
  if (cache[id]) return cache[id];
  cache[id] = axios
    .get(`${baseUrl}/products/${id}`)
    .then((result) => {
      return result.data
    });

  cache[id].finally(() => {
    delete cache[id];
  });

  return cache[id];
};

export const getProductLink = (product: Product) => {
  return `/shop/${encodeURIComponent(
    product.targetAudience
  )}/${encodeURIComponent((product.categories || []).join(","))}/${encodeURIComponent(
    product.name
  )}/${product._id}`;
}