import {
  CategoryFilter,
  ColorFilter,
  TargetAudienceFilter,
  PriceFilter,
  SearchProduct,
  SizeFilter,
  ShopFilter,
  DeleteFiltesBtn,
} from "./Filters";

import { SortBy } from "./SortBy";

export const MobileFiltersScreen: view = ({
  mobileFilterScreenOpened = observe.ui.mobileFilterScreenOpened,
  updateMobileFilterScreenOpened = update.ui.mobileFilterScreenOpened,
  resetFilters = update.triggers.resetFilters,
  triggerSearch = update.triggers.search,
}) => {
  if (!mobileFilterScreenOpened) return null;

  return (
    <div className={`mobile-filters-menu opened`}>
      <div className="akasha-menu-panels-actions-wrap">
        <a
          onClick={() => {
            updateMobileFilterScreenOpened.set(false);
            const element = document.getElementsByTagName("body");
            element[0].classList.remove("filter-menu-opened");
          }}
          className="akasha-menu-close-btn akasha-menu-close-panels"
          href="#"
        >
          x
        </a>
      </div>
      <div className="mobile-filters-menu-inner">
        <div id="widget-area" className="widget-area shop-sidebar">
          {/* <SearchProduct mobile /> */}
          <SortBy />
          <PriceFilter />
          {/* <ColorFilter /> */}
          <TargetAudienceFilter />
          <SizeFilter />
          <CategoryFilter />
          <ShopFilter />
          <DeleteFiltesBtn />
        </div>
      </div>
      <div className="mobile-filters-menu-footer">
        <button
          onClick={() => resetFilters.set(Date.now())}
          className="tertiary"
        >
          Reseteaza
        </button>
        <button
          onClick={() => {
            updateMobileFilterScreenOpened.set(false);
            const element = document.getElementsByTagName("body");
            element[0].classList.remove("filter-menu-opened");
            triggerSearch.set(Date.now());
          }}
        >
          Aplica filtrele
        </button>
      </div>
    </div>
  );
};
