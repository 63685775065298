import { useState } from "react";
import { Product } from "../../types/product";
import { AddToWishlistIcon } from "../AddToWishlist";

export const ProductGallery = ({ product }: { product: Product }) => {
  let images = product.localImages || product.images;
  const [currentImage, setCurrentImage] = useState(images[0] || {});
  

  return (
    <div className="akasha-product-gallery akasha-product-gallery--with-images akasha-product-gallery--columns-4 images">
        <AddToWishlistIcon productId={product._id} />

      <div className="flex-viewport">
        <figure className="akasha-product-gallery__wrapper">
          <div className="akasha-product-gallery__image">
            <img
              alt={product.title}
              src={currentImage.href || currentImage.url || ("/items/" + currentImage.s3url) || '/assets/images/carousel-1.png'}
              onError={({ currentTarget }) => {
                currentTarget.src = "/assets/images/placeholders/item.jpg";
              }}
            />
          </div>
        </figure>
      </div>
      <ol className="flex-control-nav flex-control-thumbs">
        {images.map((image: any, key: number) => (
          <li key={key}>
            <img
              onClick={() => setCurrentImage(image)}
              alt={product.title}
              src={image.href || image.url || ("/items/" + image.s3url)}
              width={100}
              onError={({ currentTarget }) => {
                currentTarget.src = "/assets/images/placeholders/item.jpg";
              }}
            />
          </li>
        ))}
      </ol>
    </div>
  );
};
