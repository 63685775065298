export const MobileFilterBtn: view = ({
  updateMobileFilterScreenOpened = update.ui.mobileFilterScreenOpened,
}) => {
  return (
    // <div
    //   onClick={() => updateMobileFilterScreenOpened.set(true)}
    //   className="mobile-filters"
    // >
    //   <a className="modes-mode mode-list display-mode">
    //     <span className="button-inner">
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //     </span>
    //   </a>
    // </div>
    <button
      onClick={() => {
        updateMobileFilterScreenOpened.set(true);
        const element = document.getElementsByTagName("body");
        element[0].classList.add("filter-menu-opened");
      }}
      className="mobile-filters-button"
    >
      Filtrează
      <img src="/assets/images/filter.svg" />
    </button>
  );
};
