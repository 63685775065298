import { Product } from "../types/product";
import { getProductDescription } from "./getProductDescription";

export function getProductJsonLD(product: Product): any {
  const description = getProductDescription(product);
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name,
    image: product.images.map((i) => i.href || i.url),
    sku: product.sku || `UPH-${product.merchant}-${product._id}`,
    brand:
      (product.brand && {
        "@type": "Brand",
        name: product.brand,
      }) ||
      undefined,
    description,
    offers: {
      "@type": "Offer",
      offerCount: 1,
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/UsedCondition",
      price: product.price,
      priceCurrency: "RON",
      priceValidUntil: tomorrow.toISOString(),
      shippingDetails: {
        "@type": "OfferShippingDetails",
        doesNotShip: true,
      },
    },
    hasMerchantReturnPolicy: {
      "@type": "MerchantReturnPolicy",
      applicableCountry: "RO",
      inStoreReturnsOffered: true,
      returnPolicyCategory: "https://schema.org/MerchantReturnNotPermitted",
    },
    // review: {
    //   "@type": "Review",
    //   reviewRating: {
    //     "@type": "Rating",
    //     ratingValue: 5,
    //     bestRating: 5,
    //   },
    //   author: {
    //     "@type": "Organisation",
    //     name: "Uphave",
    //   },
    // },
    // aggregateRating: {
    //   "@type": "AggregateRating",
    //   ratingValue: 5,
    //   reviewCount: 0,
    // },
  };
}
