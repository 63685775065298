import { ClipLoader } from "react-spinners";
import { ErrorMessage } from "./ErrorMessage";
import { LoadMoreBtn } from "./LoadMoreBtn";
import { ProductCard } from "./ProductCard";

export const ProductsList: view = ({
  filteredProductIds = observe.filteredProductIds,
}) => {

  if(!filteredProductIds) return null;

  const showLoading = filteredProductIds.busy;
  const productIds = filteredProductIds.items;

  return (
    <div className="auto-clear equal-container better-height akasha-products">
      <ul className="row products columns-3">
        {productIds &&
          productIds.map((id: string, i: number) => (
            <ProductCard key={`${id}-${i}`} productId={id} />
          ))}
      </ul>
      <ErrorMessage />
      {showLoading && (
        <div className="product-loader">
          <ClipLoader size={120} color="#1fa76e" speedMultiplier={0.6} />
        </div>
      )}
      <LoadMoreBtn />
    </div>
  );
};
