import { Product } from "../types/product";
import { getProductJsonLD } from "../util/getProductJsonLD";

export function ProductMicroData({ product }: { product: Product }) {
  return (
    <script type="application/ld+json">
      {JSON.stringify(getProductJsonLD(product))}
    </script>
  );
}
