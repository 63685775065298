import { ProductsListLink } from "./ProductsListLink";

export const TargetAudienceFilter: view = ({
  targetAudience = observe.ui.filters.targetAudience,
  selectedAudience = observe.queryParamObject.targetAudience,
}) => {
  selectedAudience ||= [];
  const isSelected = (k: string) => selectedAudience.find((a:string) => a === k);
  const activeFilters = (k: string) => {
    if(!isSelected(k)) return k
    return "all"
  }
  return (
    <div
      id="akasha_layered_nav-6"
      className="widget akasha widget_layered_nav akasha-widget-layered-nav"
    >
      <h2 className="widgettitle">
        Gen<span className="arrow"></span>
      </h2>
      <ul className="akasha-widget-layered-nav-list custom-radio-buttons ">
        {(Object.values(targetAudience || {}) || [])
          .filter((a) => a.order >= 0)
          .sort((a, b) => a.order - b.order)
          .map((targetAudienceObj: any) => {
            return (
              <li
                key={targetAudienceObj.name}
                className={`akasha-widget-layered-nav-list__item akasha-layered-nav-term ${
                  isSelected(targetAudienceObj.key) && "custom-radio-active"
                }`}
              >
                <ProductsListLink title={targetAudienceObj?.name} params={{targetAudience: activeFilters(targetAudienceObj?.key), categories: []}} ></ProductsListLink>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
