export const grupFavourites: producer = ({
  productsById = observe.productsById,
  favouriteProducts = observe.favouriteProductIds,
  updateGroupedFavourites = update.groupedFavouriteProductIds,
}) => {
  if (!favouriteProducts || !favouriteProducts) return;

  const favProducts = Object.keys(favouriteProducts)
    .filter((k) => favouriteProducts[k])
    .map((id) => productsById[id]);

  const grouped = favProducts.reduce((acc, product) => {
    if (!product) return acc;
    if (!acc[product.merchant]) acc[product.merchant] = {};
    acc[product.merchant][product._id] = favouriteProducts[product._id];
    return acc;
  }, {} as any);

  updateGroupedFavourites.set(grouped);
};
