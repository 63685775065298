import { merchantMap, targetAudienceMap } from "../../util/filtersMap";

export const BreadCrumbs: view = ({
  productId,
  product = observe.productsById[prop.productId],
}) => {
  if (!product) return null;
  return (
    <div className="banner-wrapper no_background">
      <div className="banner-wrapper-inner">
        <nav className="akasha-breadcrumb">
          <a href="/shop/" rel="nofollow">
            Marketplace
          </a>
          <i className="fa fa-angle-right"></i>
          <a href={"/shop/?merchant=" + product.merchant}>
            {merchantMap[product.merchant].name}
          </a>
          <i className="fa fa-angle-right"></i>
          <a href={"/shop/" + product.targetAudience}>
            {targetAudienceMap[product.targetAudience].name}
          </a>
          <i className="fa fa-angle-right"></i>
          <a
            href={"/shop/" + product.targetAudience + "/" + product.categories}
          >
            {product.categories}
          </a>
          <i className="fa fa-angle-right"></i>
          {product.name}
        </nav>
      </div>
    </div>
  );
};
