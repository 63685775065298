export const onTriggerSearch: producer = ({
  trigger = observe.triggers.search,
  fieldValue = get.ui.searchField,
  updateSearch = update.ui.filters.search,
  removeTrigger = update.triggers.search,
}) => {
  if (!trigger) return;

  updateSearch.set(fieldValue.value());
  removeTrigger.remove();
};
