import { wildcard } from "@c11/engine.runtime";
import { getOneById } from "../services/products";

export const loadProduct: producer = async ({
  productId = wildcard,
  isRequested = observe.productsById[arg.productId].isObserved(),
  product = get.productsById[arg.productId].value(),
  updateProduct = update.productsById[arg.productId],
}) => {
  if (!productId || !isRequested || product) return;
  const p = await getOneById(productId);
  updateProduct.set(p);
};
