import QueryString from "qs";
import UrlPattern from "url-pattern";

type RouteDef = {
  url: UrlPattern;
  name: string;
  data?: any;
};
const routes: RouteDef[] = [];

type Listener = {
  eventType: string,
  handler : Function
}

const listeners:Listener[] = [];
function dispatchEvent(event:any) {
  listeners
    .filter((f) => f.eventType === event.type)
    .forEach((e) => {
      try {
        e.handler(event);
      } catch (e) {
        console.error(e);
      }
    });
}

const addEventListener = (eventType: string, handler: (ev: any) => void) => {
  const listener:Listener = { eventType, handler };
  listeners.push(listener);
  const pos = listeners.length-1;
  return () => {
    listeners.splice(pos, 1);
  };
};

class NavigationEvent extends Event {
  public readonly url?: string;
  public readonly route?: RouteDef;
  public readonly params?: any;


  constructor(props: Partial<NavigationEvent>) {
    super("navigate");
    this.url = props.url;
    this.route = props.route;
    this.params = props.params;
  }
}

const update = () => {
  const path = location.pathname;
  const query = QueryString.parse(location.search.substring(1)) || {};
  const route = routes.find((r) => {
    return r.url.match(path);
  });


  const params = {...route?.url?.match(path), ...query};
  const unmarshaledParams = Object.keys(params).reduce((s, k) => {
    if(params[k]) s[k] = decodeURIComponent(params[k]).split(",");
    return s;
  }, {} as any)

  dispatchEvent(
    new NavigationEvent({
      url: location.href,
      route,
      params: unmarshaledParams,
    })
  );
};

const addRoute = (name: string, pattern: string, data?: any) => {
  routes.push({
    url: new UrlPattern(pattern, {segmentValueCharset: 'a-zA-Z0-9-_~ %.,()\''}),
    name,
    data,
  });
};

const init = () => {
  window.addEventListener("popstate", (e:PopStateEvent) => {
    e.preventDefault();
    e.stopPropagation();
    update();
    return false;
  });

  window.addEventListener("pushState", (e: any) => {
    window.history.pushState(
      null,
      e.title,
      e.url
    );
    update();
    e.preventDefault();
    e.stopPropagation();
    return false;
  })

  update();
};

const getLink = (routeName:string, params:any) => {
  const route = routes.find((r) => {
    return r.name == routeName
  })
  return route?.url.stringify(params);
}

export const router = {
  addRoute,
  addEventListener,
  init,
  update,
  getLink
};
