import { Product } from "src/types/product";
import { concat } from "../Components/ProductCard";

export function getProductDescription(product: Product) {
  return concat([
    product.brand,
    product.name,
    product.colour,
    ";",
    ["Condition: ",(product.condition || "N/A")],
    ",\n",
    ["Size: ",(product.size || "N/A")],
    ",\n",
    ["Merchant:",product.merchant]
  ]);
}
