export const colourMap = {
  Alb: { name: "Alb", value: "#ffffff", light: true },
  Negru: { name: "Negru", value: "#000000" },
  Gri: { name: "Gri", value: "gray" },
  Albastru: { name: "Albastru", value: "blue" },
  Verde: { name: "Verde", value: "green" },
  Roșu: { name: "Roșu", value: "#FF0000" },
  Crem: { name: "Crem", value: "#FFFDD0", light: true },
  Mov: { name: "Mov", value: "#e0b0ff" },
  Maro: { name: "Maro", value: "#800000" },
  Galben: { name: "Galben", value: "#FFFF00", light: true },
  Bordo: { name: "Bordo", value: "#5F021F" },
  Turcoaz: { name: "Turcoaz", value: "#30D5C8" },
  Roz: { name: "Roz", value: "#FFC0CB" },
  Portocaliu: { name: "Portocaliu", value: "#FFA500" },
  "Verde mentă": { name: "Verde mentă", value: "#3EB489" },
  Kaki: { name: "Kaki", value: "#8A865D" },
  Argintiu: { name: "Argintiu", value: "#C0C0C0" },
  Corai: { name: "Corai", value: "#FF7F50" },
};

export const merchantMap = {
  arhivadehaine: { name: "Arhiva de Haine" },
  LaraShop: { name: "Lara shop" },
  Lamajole: { name: "Lamajole" },
  Mamut: { name: "Mamut" },
  MarajoVintage: { name: "Marajo Vintage" },
  VintageCollection: { name: "Vintage collection" },
  ThriftShopRo: { name: "Thriftshop ro" },
  Equantum: { name: "Equantum" },
  IntreUmerase: { name: "Intre umerase" },
  RetroActivShop: { name: "Retroactiv shop" },
  Modamoon: { name: "Modamoon" },
  Vintager: { name: "Vintager" },
  OpShopVintage: {name: "OpShopVintage"}
};

export const categoriesMap = {
  Accesorii: { name: "Accesorii", key: "Accesorii", order: 1 },
  Bluze: { name: "Bluze", key: "Bluze", order: 2 },
  Tricouri: { name: "Tricouri", key: "Tricouri", order: 3 },
  Pantaloni: { name: "Pantaloni", key: "Pantaloni", order: 4 },
  Rochii: { name: "Rochii", key: "Rochii", order: 5 },
  Blugi: { name: "Blugi", key: "Blugi", order: 6 },
  "Geci și Paltoane": {
    name: "Geci și Paltoane",
    key: "Geci și Paltoane",
    order: 7,
  },
  Fuste: { name: "Fuste", key: "Fuste", order: 8 },
  Încălțăminte: { name: "Încălțăminte", key: "Încălțăminte", order: 9 },
  Cămăși: { name: "Cămăși", key: "Cămăși", order: 10 },
  Pulovere: { name: "Pulovere", key: "Pulovere", order: 11 },
  Hanorace: { name: "Hanorace", key: "Hanorace", order: 12 },
  Costume: { name: "Costume", key: "Costume", order: 13 },
  Salopete: { name: "Salopete", key: "Salopete", order: 14 },
  Sacouri: { name: "Sacouri", key: "Sacouri", order: 15 },
  Colonti: { name: "Colonti", key: "Colonti", order: 16 },
  Ciorapi: { name: "Ciorapi", key: "Ciorapi", order: 17 },
  Maiouri: { name: "Maiouri", key: "Maiouri", order: 18 },
  Halate: { name: "Halate", key: "Halate", order: 19 },
  Veste: { name: "Veste", key: "Veste", order: 20 },
  "Costume de baie": {
    name: "Costume de baie",
    key: "Costume de baie",
    order: 21,
  },
  "Imbracăminte sportivă": {
    name: "Imbracăminte sportivă",
    key: "Imbracăminte sportivă",
    order: 22,
  },
  "Lenjerie de noapte": {
    name: "Lenjerie de noapte",
    key: "Lenjerie de noapte",
    order: 23,
  },
  "Lenjerie intima": {
    name: "Lenjerie intima",
    key: "Lenjerie intima",
    order: 24,
  },
};

export const targetAudienceMap = {
  men: {
    name: "Barbați",
    order: 1,
    key: "men",
  },
  women: {
    name: "Femei",
    order: 0,
    key: "women",
  },
  kids: {
    name: "Copii",
    order: 2,
    key: "kids",
  },
  any: {
    name: "Altele",
    order: -1,
    key: "any"
  }
};
export const sizeMap = {
  XXS: {
    name: "XXS",
    key: "XXS",
    order: 0,
  },
  XS: {
    name: "XS",
    key: "XS",
    order: 1,
  },
  S: {
    name: "S",
    key: "S",
    order: 2,
  },
  M: {
    name: "M",
    key: "M",
    order: 3,
  },
  L: {
    name: "L",
    key: "L",
    order: 4,
  },
  XL: {
    name: "XL",
    key: "XL",
    order: 5,
  },
  "2XL": {
    name: "2XL",
    key: "2XL",
    order: 5,
  },
  "3XL": {
    name: "3XL",
    key: "3XL",
    order: 6,
  },
};
