export const resetFilters: producer = async ({
  resetFilters = observe.triggers.resetFilters,
  cleanFilters = get.ui.cleanFilters,
  updateFilters = update.ui.filters,
  updateTriggerGetProducts = update.triggers.getProducts.type,
}) => {
  if (!resetFilters) return;
  updateFilters.set(cleanFilters.value());
  updateTriggerGetProducts.set("newProducts");
};
