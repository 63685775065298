import { HTMLAttributes } from "react";
import { Link } from "../../lib/router/link";
import { getProductLink } from "../../services/products";
import { Product } from "../../types/product";
import { getProductDescription } from "../../util/getProductDescription";

type ProductLinkProps = HTMLAttributes<HTMLAnchorElement> & {
    product: Product,
}
export const ProductLink = (props:ProductLinkProps ) => {
    const product = props.product;
    const productLink = getProductLink(product);
    const description = getProductDescription(product);
    return (<Link
    {...props}
        id={`product-${product._id}`}
        href={productLink}
        className="thumb-link"
        title={description}
    ></Link>)

}