import { sizeMap } from "src/util/filtersMap";

export const onCategoryChange: producer = async ({
  trigger = observe.trigger.categoryChange,
  categories = get.ui.filters.categories,
  originalFilterOption = get.originalFilterOption,
  cleanFilters = get.ui.cleanFilters,
  targetAudienceFilter = get.ui.filters.targetAudience,
  oldSizes = get.ui.filters.size,
  removeTrigger = update.trigger.categoryChange,
  oldFilters = get.ui.filters,
  updateFilters = update.ui.filters,
}) => {
  if (!trigger) return;
  try {
    oldSizes = oldSizes.value();
    originalFilterOption = originalFilterOption.value();

    categories = categories.value();
    targetAudienceFilter = targetAudienceFilter.value();
    let selectedCategories: any = [];

    Object.keys(categories).forEach((key) => {
      if (key === trigger.key) {
        trigger.selected && selectedCategories.push(key);
      } else if (categories[key].selected) {
        selectedCategories.push(key);
      }
    });

    let sizes: any = {};

    if (selectedCategories.length) {
      const selectedTargetAudience = Object.values(targetAudienceFilter).filter(
        (x: any) => x.selected
      )?.[0]?.key;

      selectedCategories.forEach((category: any) => {
        if (selectedTargetAudience) {
          originalFilterOption?.sizesByTargetAudienceAndCategory?.[category][
            selectedTargetAudience
          ]?.forEach((size: string) => {
            if (!sizes[size]) {
              sizes[size] = {
                name: size !== "One Size Fits All" ? size : "OSFA",
                selected: oldSizes?.[size]?.selected,
                order: sizeMap[size]?.order,
                key: size,
              };
            }
          });
        } else {
          originalFilterOption?.sizesByCategory?.[category].forEach(
            (size: string) => {
              if (!sizes[size]) {
                sizes[size] = {
                  name: size !== "One Size Fits All" ? size : "OSFA",
                  selected: oldSizes?.[size]?.selected,
                  order: sizeMap[size]?.order,
                  key: size,
                };
              }
            }
          );
        }
      });
    } else {
      sizes = cleanFilters.value().size;
    }
    oldFilters = oldFilters.value();
    delete oldFilters.size;
    oldFilters.categories[trigger.key].selected = trigger.selected;
    updateFilters.set({ ...oldFilters, size: sizes });
    removeTrigger.remove();
  } catch (err) {
    console.error("ERROR", err);
  }
};
