export const AddToWishlistButton = ({ product }) => {
  return null;
  return (
    <div className="yith-wcwl-add-to-wishlist">
      <div className="yith-wcwl-add-button show">
        <a
          href="#"
          rel="nofollow"
          data-product-id={product._id}
          className="add_to_wishlist"
        >
          Adauga la favorite
        </a>
      </div>
    </div>
  );
};

export const AddToWishlistIcon: view = ({
  productId,
  isFavourite = observe.favouriteProductIds[prop.productId],
  updateFavourite = update.favouriteProductIds[prop.productId],
}) => {
  const actionText = isFavourite
    ? "Elimina din favorite."
    : "Adauga la favorite.";
  return (
    <a
      href="#"
      title={actionText}
      className={"whishlist__trigger " + (isFavourite ? "selected" : "")}
      onFocus={(e) => e.currentTarget.blur()}
      onClick={(e) => {
        e.preventDefault();
        updateFavourite.set(!isFavourite);
        return false;
      }}
    >
      <span className="emoji">
        <i>{actionText}</i>
      </span>
    </a>
  );
};
