import { TryAgainBtn } from "./TryAgainBtn";

export const ErrorMessage: view = ({
  errorGettingProducts = observe.ui.errorGettingProducts,
}) => {
  if (!errorGettingProducts) return null;
  return (
    <div
      style={{
        fontSize: 18,
        color: "red",
        margin: "auto",
        marginBottom: 20,
      }}
    >
      <p style={{ marginBottom: 4 }}> S-a produs o eroare de conexiune!</p>

      <TryAgainBtn />
    </div>
  );
};
