export const SearchProduct: view = ({
  mobile,
  internalValue = observe.ui.searchField,
  filterValue = observe.ui.filters.search,
  updateValue = update.ui.searchField,
  triggerSearch = update.triggers.search,
}) => {
  const value = internalValue === undefined ? filterValue : internalValue;
  return (
    <div
      id="akasha_product_search-2"
      className="widget akasha widget_product_search"
    >
      <form
        className="akasha-product-search"
        onSubmit={(e) => {
          if (!mobile) {
            triggerSearch.set(Date.now());
          }
          e.preventDefault();
        }}
      >
        <input
          id="akasha-product-search-field-0"
          className="search-field"
          placeholder="Caută produse…"
          value={value || ""}
          name="s"
          type="search"
          onChange={(e) => {
            updateValue.set(e.target.value);
          }}
        />
        {value && (
          <i
            className="fa fa-x search-cancel-button"
            onClick={() => {
              updateValue.set("");
              triggerSearch.set(Date.now());
            }}
          />
        )}
        {<button type="submit" value="s" />}
      </form>
    </div>
  );
};
