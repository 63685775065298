import { categoriesMap, targetAudienceMap } from "../util/filtersMap";
import { getProductDescription } from "../util/getProductDescription";

class IdentityFunc {
  get(k: string) {
    return { name: k };
  }
}

const maps = [targetAudienceMap, categoriesMap, new IdentityFunc()];
export const updateUrl: producer = ({
  queryParam = observe.queryParamObject,
  productId = observe.queryParamObject.productId,
  product = observe.productsById[arg.productId],
}) => {
  // return;
  if (!queryParam) return;
  try {
    // let newPath = [`/shop`];
    let newTitle: string[] = [];

    ["targetAudience", "categories"].forEach((k, i) => {
      let value = queryParam[k];

      if (value) {
        const valueMapper: any = maps[i] as any;
        let titlePart = "";
        if (Array.isArray(value)) {
          titlePart = value.reduce((s, k) => {
            return s + " " + valueMapper[k]?.name;
          }, "");
        } else {
          titlePart = valueMapper?.[value]?.name;
        }
        if (titlePart) newTitle.push(titlePart);
      }
    });

    if (productId && product) {
      newTitle.push(product.name);
      const image = product.images[0];
      if(image)
        document
          .querySelector('meta[property="og:image"]')
          ?.setAttribute("content", "https://uphave.eu" + (image.href || image.url));

        document
        .querySelector('meta[property="og:title"]')
        ?.setAttribute("content", "Uphave: "+product.name);
    } else {
      const elem = document.getElementById("product-jsonld");
      if (elem) {
        elem.remove();
      }
      document
        .querySelector('meta[property="og:image"]')
        ?.setAttribute("content", "https://uphave.eu/assets/images/logo.svg");
        document
        .querySelector('meta[property="og:title"]')
        ?.setAttribute("content", "Uphave: Marketplace");
    }

    document
        .querySelector('meta[property="og:url"]')
        ?.setAttribute("content", location.href);

    if (newTitle.length) {
      window.document.title = "Uphave: " + newTitle.reverse().join(" ");
      const description =
        "Cumpără produse " +
        newTitle.join(" ") +
        ((product && getProductDescription(product)) || " ") +
        ". Marketplace-ul magazinelor Fashion Vintage / Second-Hand. ";
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute("content", description);
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute("content", description);
    } else window.document.title = "Uphave: Marketplace";
  } catch (e) {
    console.error(e);
  }
};
