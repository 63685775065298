import { SearchProduct } from "../Components/Filters";
import { MobileFilterBtn } from "../Components/MobileFilterBtn";
import { MobileFiltersScreen } from "../Components/MobileFiltersScreen";
import { ProductsList } from "../Components/ProductsList";
import { Sidebar } from "../Components/Sidebar";
import { SortBy } from "../Components/SortBy";

export const ProductsListPage = () => (
  <>
    <div className="slide-home-01">
      <div
        className="response-product product-list-owl owl-slick equal-container better-height"
        data-slick='{"arrows":false,"slidesMargin":0,"dots":true,"infinite":false,"speed":300,"slidesToShow":1,"rows":1, "autoplay": false}'
        data-responsive='[{"breakpoint":480,"settings":{"slidesToShow":1,"slidesMargin":"0"}},{"breakpoint":768,"settings":{"slidesToShow":1,"slidesMargin":"0"}},{"breakpoint":992,"settings":{"slidesToShow":1,"slidesMargin":"0"}},{"breakpoint":1200,"settings":{"slidesToShow":1,"slidesMargin":"0"}},{"breakpoint":1500,"settings":{"slidesToShow":1,"slidesMargin":"0"}}]'
      >
        <div className="slide-wrap slide-wrap-1">
          <div className="container">
            <div className="slider-inner">
              <div className="slider-inner-left">
                <img src="/assets/images/carousel-1.png" alt="" />
              </div>
              <div className="slider-inner-right">
                <img
                  className="store-logo"
                  src="/assets/images/store-logo-1.svg"
                  alt="Marketplace-ul magazinelor Fashion Vintage / Second-Hand."
                />
                <h3>
                  Marketplace-ul magazinelor Fashion Vintage / Second-Hand.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-wrap slide-wrap-1" style={{ display: "none" }}>
          <div className="container">
            <div className="slider-inner">
              <div className="slider-inner-left">
                <img src="/assets/images/carousel-2.png" alt="" />
              </div>
              <div className="slider-inner-right">
                <img
                  className="store-logo"
                  src="/assets/images/store-logo-2.svg"
                  alt="Mii de oferte, direct din magazine."
                />
                <h3>Mii de oferte, direct din magazine.</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-wrap slide-wrap-1" style={{ display: "none" }}>
          <div className="container">
            <div className="slider-inner">
              <div className="slider-inner-left">
                <img height="463" src="/assets/images/carousel-3.png" alt="" />
              </div>
              <div className="slider-inner-right">
                <img
                  className="store-logo"
                  src="/assets/images/store-logo-3.svg"
                  alt="Tu alegi culoarea anului."
                />
                <h3>Tu alegi culoarea anului.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="main-container shop-page left-sidebar">
      <div className="container">
        <div className="row">
          <div className="main-content col-xl-9 col-lg-8 col-md-8 col-sm-12 has-sidebar">
            <div className="shop-control shop-before-control">
              <MobileFilterBtn />
              <SortBy />
              <SearchProduct />
            </div>
            <ProductsList />
          </div>
          <Sidebar />
          <MobileFiltersScreen />
        </div>
      </div>
    </div>
  </>
);
