import { AddToWishlistIcon } from "./AddToWishlist";
import { ProductLink } from "./Product/ProductLink";
import { ProductMicroData } from "./ProductMicroData";

const isDefined = (item: any) => !!item;

export const concat = (ar: any[]) => {
  return ar.reduce((s, i) => {
    let item = i;
    if (Array.isArray(item) && item.every(isDefined)) {
      item = concat(item);
    }
    if (item) return s + " " + item.trim();
    return s;
  }, "");
};
export const ProductCard: view = ({
  productId,
  product = get.productsById[prop.productId].value(),
  key
}) => {
  const images = product.localImages || product.images
  return (
    <li
      className="product-item wow fadeInUp product-item rows-space-30 col-bg-4 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-ts-6 style-01 post-24 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-table product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock first instock featured shipping-taxable purchasable product-type-variable has-default-attributes"
      data-wow-duration="1s"
      data-wow-delay="0ms"
      data-wow="fadeInUp"
      key={productId}

    >
      <a id={`product-${product._id}`} style={{display:'none'}}>{productId}</a>
      <ProductMicroData product={product} />
      <div className="product-inner tooltip-left">
        <div className="product-thumb">
          <AddToWishlistIcon productId={productId} />
          <ProductLink product={product}>
            <img
              className="img-responsive"
              referrerPolicy="no-referrer"
              src={images?.[0]?.href || images?.[0]?.url || ("/items/" + images?.[0]?.s3url)}
              alt={product.name}
              title={product.name}
              height="340"
              loading="lazy"
              onError={({ currentTarget }) => {
                currentTarget.src = "/assets/images/placeholders/item.jpg"
                // if (currentTarget.src === (images?.[0]?.url || ("/items/" + images?.[0]?.s3url))) return;
                // currentTarget.src = images?.[0]?.url || ("/items/" + images?.[0]?.s3url);
              }}
            />
          </ProductLink>
        </div>
        <div className="product-info">
          <h3 className="product-name product_title">{product.name}</h3>
          <div className="buy-section">
            <span className="price">
              <span className="akasha-Price-amount amount">
                {product.price}
                <span className="akasha-Price-currencySymbol">Lei</span>
              </span>
            </span>
            <ProductLink className="buy-btn" product={product}>
              <button className="buy-button">Detalii</button>
            </ProductLink>
          </div>
        </div>
      </div>
    </li>
  );
};
