import {
  CategoryFilter,
  DeleteFiltesBtn,
  PriceFilter,
  SearchProduct,
  ShopFilter,
  SizeFilter,
  TargetAudienceFilter,
} from "./Filters";

export const Sidebar = () => (
  <div className="sidebar col-xl-3 col-lg-4 col-md-4 col-sm-12">
    <div id="widget-area" className="widget-area shop-sidebar">
      <SearchProduct />
      <PriceFilter />
      {/* <ColorFilter /> */}
      <TargetAudienceFilter />
      <SizeFilter />
      <CategoryFilter />
      <ShopFilter />
      <DeleteFiltesBtn />
    </div>
  </div>
);
