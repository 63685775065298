export const filtersOfTypeSelect = [
  "colour",
  "categories",
  "merchant",
  "size",
  "targetAudience",
];
export const filtersOfTypeValue = ["priceGte", "priceLte"];
export const priceMaxValue = 3000;
export const priceFilterLimits = [
  [0, 100],
  [100, 250],
  [250, 500],
];
