import { MouseEventHandler } from "react";
import { BuyButton } from "../BuyButton";
import { ProductLink } from "./ProductLink";

export const FavouriteProduct: view = ({
  productId,
  product = observe.productsById[prop.productId],
  productIsFavourite = update.favouriteProductIds[prop.productId],
}) => {
  const remooveFromFav: MouseEventHandler = (event) => {
    event.preventDefault();
    // console.log("Remove from Favs", productId)
    productIsFavourite.set(false);
  };
  if (!product || product.loading)
    return (
      <tr>
        <td colSpan={6}>loading... {productId}</td>
      </tr>
    );
  return (
    <tr>
      <td className="product-remove">
        <div>
          <a
            href="#"
            onClick={remooveFromFav}
            className="remove remove_from_wishlist"
            title="Remove this product"
          >
            ×
          </a>
        </div>
      </td>
      <td className="product-thumbnail">
        <ProductLink product={product}>
          <img
            src={product.images[0] && (product.images[0].href || product.images[0].url)}
            className="attachment-akasha_thumbnail size-akasha_thumbnail"
            alt="img"
            width="600"
            height="778"
          />{" "}
        </ProductLink>
      </td>
      <td className="product-price">
        <ProductLink product={product}>{product.name}</ProductLink>
      </td>
      <td className="product-price">
        <span className="akasha-Price-amount amount">
          {product.price}&nbsp;
          <span className="akasha-Price-currencySymbol">Lei</span>
        </span>
      </td>
      <td className="product-add-to-cart">
        <BuyButton product={product} />
      </td>
    </tr>
  );
};
