import { ClipLoader } from "react-spinners";
import { getProductJsonLD } from "../../util/getProductJsonLD";
import { AddToWishlistButton } from "../AddToWishlist";
import { BuyButton } from "../BuyButton";
import { ProductGallery } from "./ProductGallery";
import { merchantMap } from "src/util/filtersMap";

export const ProductWide: view = ({
  productId,
  product = observe.productsById[prop.productId],
}) => {
  if (!product)
    return <ClipLoader size={120} color="#1fa76e" speedMultiplier={0.6} />;

  const elemId = "product-jsonld";
  let elem = document.getElementById(elemId);
  if (elem) {
    elem.remove();
  }
  elem = document.createElement("script");
  elem.setAttribute("type", "application/ld+json");
  elem.setAttribute("id", elemId);
  elem.innerText = JSON.stringify(getProductJsonLD(product));
  document.head.appendChild(elem);

  return (
    <>
      {/* <ProductMicroData product={product} /> */}
      <div className="contain-left has-gallery">
        <div className="single-left">
          <ProductGallery product={product} />
        </div>
        <div className="summary entry-summary">
          <h1 className="product_title entry-title">{product.name}</h1>
          <p className="price">
            <span className="akasha-Price-amount amount">
              {product.price}
              <span className="akasha-Price-currencySymbol">
                {product.currency || "Lei"}
              </span>
            </span>
          </p>
          <p className="stock in-stock">
            Availability: <span> In stock</span>
          </p>
          <div className="akasha-product-details__short-description">
            <ul>
              {product.brand && <li>Brand: {product.brand || "?"}</li>}
              {product.colour && <li>Culoare: {product.colour || "?"}</li>}
              <li>Marime: {product.size}</li>
              <li>Stare: {product.condition}</li>
              <li>
                Magazin{" "}
                <a
                  href={
                    "/shop/" +
                    product.targetAudience +
                    "/" +
                    product.categories +
                    "?merchant=" +
                    product.merchant
                  }
                >
                  {merchantMap[product.merchant].name || product.merchant}
                </a>
              </li>
            </ul>
          </div>
          <BuyButton product={product} />
          <AddToWishlistButton product={product} />

          <div className="product_meta">
            <span className="sku_wrapper">
              {/* SKU:{" "}
              <span className="sku">
                {product.merchant}/{product.sku}
              </span> */}
            </span>
            <span className="posted_in">
              Categorii:{" "}
              {product.categories?.map((c: string, i: number) => {
                return (
                  <a
                    key={i}
                    href={"/shop/" + product.targetAudience + "/" + c}
                    rel="tag"
                  >
                    {c}
                  </a>
                );
              })}
            </span>
            {/* <span className="tagged_as">
              Tags:{" "}
              <a href="#" rel="tag">
                Bags
              </a>
              ,{" "}
              <a href="#" rel="tag">
                Sock
              </a>
            </span> */}
          </div>
          {/* <ShareSocials product={product} /> */}
        </div>
      </div>
    </>
  );
};
