export const onPageNumberChange: producer = async ({
  pageNumber = observe.ui.pageNumber,
  updateQueryParam = update.queryParamObject.pageNumber,
  updateTriggerGetProducts = update.triggers.getProducts.type,
}) => {
  if (pageNumber) {
    updateQueryParam.set(pageNumber);
    updateTriggerGetProducts.set("newPage");
  }
};
