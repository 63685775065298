import { router } from "../lib/router/routes";

let initialized = false;
export const onPageLoad: producer = async ({
  updateUrlObj = update.queryParamObject,
  updateRoute = update.route,
}) => {
  try {
    if (initialized) return;
    initialized = true;

    router.addEventListener("navigate", (event) => {
      const urlObj: any = { targetAudience: "" };
      if (event.params) {
        Object.keys(event.params).forEach((k) => (urlObj[k] = event.params[k]));

        const { targetAudience, categories, productName, productId } =
          event.params;
        if (targetAudience?.length && targetAudience[0] != "any") {
          urlObj.targetAudience = targetAudience;
        }
        if (!urlObj.targetAudience) urlObj.targetAudience = ["women"];

        if (categories?.length && categories[0] !== "any") {
          urlObj.categories = categories;
        }
        if (productId) {
          urlObj.productId = productId.join(",");
          urlObj.productName = productName.join(",");
        }

        updateRoute.set({ ...event.route, params: event.params });
      } else {
        updateRoute.set({ routeName: "404" });
      }
      updateUrlObj.set(urlObj);
    });

    router.addRoute("Favourites", "/shop/favourites*", {});
    router.addRoute(
      "ProductsList",
      "/shop(/:targetAudience(/:categories))(?(&merchant=:merchant)(&size=:size))",
      {}
    );
    router.addRoute(
      "ProductDetails",
      "/shop/:targetAudience/(:categories)/(:productName)/:productId",
      {}
    );

    router.init();
  } catch (err) {
    console.error(err);
  }
};
