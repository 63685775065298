
export const LoadMoreBtn: view = ({
  collection = observe.filteredProductIds,
  pageNumber = observe.ui.pageNumber,
  updatePageNumber = update.ui.pageNumber,
}) => {
  if (!collection || collection.busy || !collection.hasMore || collection.error) return null;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
        <button
          className="secondary"
          onClick={() => {
            updatePageNumber.set(pageNumber + 1);
          }}
        >
          Încarcă mai multe
        </button>
    </div>
  );
};
