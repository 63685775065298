import { connectToDashboard } from "@c11/engine.dashboard";
import { debug } from "@c11/engine.patterns";
import { render } from "@c11/engine.react";
import { engine, pathFn, producers } from "@c11/engine.runtime";
import { EventNames } from "@c11/engine.types";
import { App } from "./App";
import "./global";
import * as localProducers from "./producers";


export const renderApp = (mountPoint: String | HTMLElement) => {
  let app;

  const state = {
      products: [],
      ui: {
        pageNumber: 0,
      },
      queryParamObject: { targetAudience: "women" },
      productIdsByFilter: {},
      productsById: {},
  }

  if (process.env.NODE_ENV === "development") {
    const dashboard = connectToDashboard();
    const receiveUpdates: producer = ({ updatePath = update }) => {
      dashboard.on((event: any) => {
        event.data.text().then((x) => {
          const op = JSON.parse(x);
          const result = updatePath(pathFn(...op.path));
          result.set(op.value);
        });
      });
    };

    app = engine({
      onEvents: {
        [EventNames.STATE_UPDATED]: dashboard.send,
        [EventNames.PATCH_APPLIED]: dashboard.send,
      },
      state,
      use: [
        producers([...Object.values(localProducers), receiveUpdates, debug]),
        render(<App />, mountPoint, { debug: false }),
      ],
    });
  } else {
    app = engine({
      state,
      use: [
        producers([...Object.values(localProducers)]),
        render(<App />, mountPoint, { debug: false }),
      ],
    });
  }
  return app;
};
