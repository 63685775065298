import axios from "axios";
import { baseUrl } from "../config";
import {
  filtersOfTypeSelect,
  filtersOfTypeValue,
  priceFilterLimits,
} from "../util/filterType";
import {
  categoriesMap,
  colourMap,
  merchantMap,
  sizeMap,
  targetAudienceMap,
} from "../util/filtersMap";

export const getFiltersOptions: producer = async ({
  originalFilterOption = observe.originalFilterOption,
  queryParamObject = get.queryParamObject,
  updateFilters = update.ui.filters,
  updateCleanFilters = update.ui.cleanFilters,
  updateOriginalFilterOption = update.originalFilterOption,
}) => {
  if (originalFilterOption) return;
  updateOriginalFilterOption.set({});
  try {
    const response = await axios.get(`${baseUrl}/filters`);
    const filtersRes = response.data;
    updateOriginalFilterOption.set(filtersRes);

    const colour: any = {};
    filtersRes.colours?.forEach((c: any) => {
      if (colourMap[c]) {
        colour[c] = JSON.parse(JSON.stringify(colourMap[c]));
      }
    });

    const merchant = {};
    filtersRes.shops?.forEach((s: any) => {
      if (merchantMap[s]) {
        merchant[s] = JSON.parse(JSON.stringify(merchantMap[s]));
      }
    });

    const categories = {};
    filtersRes.allCategories?.forEach((c: any) => {
      if (categoriesMap[c]) {
        categories[c] = JSON.parse(JSON.stringify(categoriesMap[c]));
      }
    });

    const filters: any = {
      colour,
      categories,
      merchant,
      size: JSON.parse(JSON.stringify(sizeMap)),
      targetAudience: JSON.parse(JSON.stringify(targetAudienceMap)),
    };
    updateCleanFilters.set(filters);
    queryParamObject = queryParamObject.value();

    if (queryParamObject) {
      //add dynamic options
      const selectedCategories = queryParamObject?.categories;
      const targetAudience = queryParamObject?.targetAudience;

      if (selectedCategories?.length) {
        let sizes: any = {};
        selectedCategories.forEach((category: any) => {
          if (targetAudience) {
            filtersRes?.sizesByTargetAudienceAndCategory?.[category]?.[
              targetAudience
            ]?.forEach((size: string) => {
              if (!sizes[size]) {
                sizes[size] = {
                  name: size !== "One Size Fits All" ? size : "OSFA",
                  order: sizeMap[size]?.order,
                  key: size,
                };
              }
            });
          } else {
            filtersRes.sizesByCategory?.[category]?.forEach((size: string) => {
              if (!sizes[size]) {
                sizes[size] = {
                  name: size !== "One Size Fits All" ? size : "OSFA",
                  key: size,
                };
              }
            });
          }
        });
        filters.size = sizes;
      }
      if (targetAudience?.length) {
        let categories = {};
        filtersRes.categoriesByAudience?.[0]?.[targetAudience[0]]?.forEach(
          (c: any) => {
            if (categoriesMap[c]) {
              categories[c] = JSON.parse(JSON.stringify(categoriesMap[c]));
            }
          }
        );
      }

      //populate filters with param values
      Object.keys(queryParamObject).forEach((filter) => {
        if (filtersOfTypeSelect.includes(filter)) {
          const selected = queryParamObject[filter];
          if (selected?.length > 0) {
            selected.forEach((s) => {
              // temp solution
              if (s !== "One Size Fits All") {
                filters[filter][s] = filters[filter][s] || { name: s, key: s };
                filters[filter][s].selected = true;
              }
            });
          }
        }
        if (filtersOfTypeValue.includes(filter)) {
          filters[filter] = queryParamObject[filter];
        }
        if (filter == "search") {
          filters[filter] = decodeURIComponent(queryParamObject["search"]);
        }
      });
    }

    //initial price filter option
    if (filters["priceGte"] && filters["priceLte"]) {
      const priceGte = filters["priceGte"];
      const priceLte = filters["priceLte"];

      priceFilterLimits.forEach((interval, index) => {
        if (priceGte == interval[0] && priceLte == interval[1]) {
          filters.priceFilterOption = index + 1;
        }
      });

      if (priceGte && priceLte && !filters.priceFilterOption) {
        filters.priceFilterOption = 4;
      }
    }
    updateFilters.set(filters);
  } catch (err) {
    console.error("ERROR", err);
  }
};
