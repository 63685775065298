import { ProductsListLink } from "./ProductsListLink";

export const CategoryFilter: view = ({
  categories = observe.ui.filters.categories,
  selectedCategories = observe.queryParamObject.categories,
}) => {
  if (!selectedCategories) selectedCategories = [];

  const selectedMap = selectedCategories.reduce((acc:any, k:string) => {
    acc[k] = true;
    return acc;
  }, {})

  const isSelected = (key:string) => {
    return selectedMap[key];
  }

  const processCategories = (key:string) => {
    let reply = [];
    if (isSelected(key)) {
      reply= selectedCategories.filter((s:string) => s !== key)
    } else {
      reply= [...selectedCategories, key];
    }
    return reply.join(",");
  };

  return (
    <>
      <div
        id="akasha_product_categories-3"
        className="widget akasha widget_product_categories"
      >
        <h2 className="widgettitle">
          Categorii<span className="arrow"></span>
        </h2>
        <ul className="product-categories">
          {Object.values(categories || {})
            .sort((a, b) => a.order - b.order)
            .map((categoryObj: any) => {
              return (
                <li
                  key={categoryObj.name}
                  className={`checkbox-item ${
                    isSelected(categoryObj.key) && "checbox-item-active"
                  }`}
                >
                  <ProductsListLink title={categoryObj?.name} params={{categories: processCategories(categoryObj.key)}} ></ProductsListLink>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};
