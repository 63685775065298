import { FavouritesPage } from "./pages/FavouritesPage";
import { ProductsDetailsPage } from "./pages/ProductDetailsPage";
import { ProductsListPage } from "./pages/ProductsListPage";

export const App: view = ({ route = observe.route }) => {
  switch (route.name) {
    case "ProductDetails":
      return <ProductsDetailsPage />;
      break;
    case "Favourites":
      return <FavouritesPage />;
      break;
    default:
      return <ProductsListPage />;
  }
};
// App.producers([
//   onPageLoad,
//   updateUrl,
//   saveFavsToLocalStorage,
//   loadFavsToLocalStorage,
// ]);
