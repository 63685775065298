import { categoriesMap, sizeMap } from "src/util/filtersMap";

export const onTargetAudienceChange: producer = async ({
  trigger = observe.trigger.targetAudienceChange,

  originalFilterOption = get.originalFilterOption,
  targetAudienceFilter = get.ui.filters.targetAudience,
  cleanFilters = get.ui.cleanFilters,
  oldCategories = get.ui.filters.categories,
  oldSizes = get.ui.filters.size,
  updateTargetAudience = update.ui.filters.targetAudience,
  updateCategories = update.ui.filters.categories,
  updateSize = update.ui.filters.size,
  removeTrigger = update.trigger.targetAudienceChange,
}) => {
  if (!trigger) return;
  try {
    cleanFilters = cleanFilters.value();

    originalFilterOption = originalFilterOption.value();
    targetAudienceFilter = targetAudienceFilter.value();
    oldCategories = oldCategories.value();
    oldSizes = oldSizes.value();
    let categories = {};
    let sizes: any = {};
    const isSelected = targetAudienceFilter[trigger].selected;
    if (isSelected) {
      categories = { ...cleanFilters.categories };
      sizes = { ...cleanFilters.size };
    } else {
      originalFilterOption.categoriesByAudience?.[0]?.[trigger]?.forEach(
        (c: any) => {
          const categoryIsSelected = oldCategories?.[c]?.selected;
          if (categoriesMap[c]) {
            categories[c] = {
              ...categoriesMap[c],
              selected: categoryIsSelected,
            };
          }
          if (categoryIsSelected) {
            const sizesByTargetAudienceAndCategory =
              originalFilterOption?.sizesByTargetAudienceAndCategory?.[c][
                trigger
              ];
            if (sizesByTargetAudienceAndCategory?.length) {
              sizesByTargetAudienceAndCategory.forEach((size: any) => {
                if (!sizes[size]) {
                  sizes[size] = {
                    name: size !== "One Size Fits All" ? size : "OSFA",
                    key: size,
                    selected: oldSizes?.[size]?.selected,
                    order: sizeMap[size]?.order,
                  };
                }
              });
            }
          }
        }
      );
    }
    Object.keys(targetAudienceFilter).forEach((key) => {
      if (key === trigger && !targetAudienceFilter[key].selected)
        targetAudienceFilter[key].selected = true;
      else {
        targetAudienceFilter[key].selected = false;
      }
    });

    if (!Object.keys(sizes).length) {
      sizes = { ...cleanFilters.size };
    }
    updateSize.set(sizes);
    updateCategories.set(categories);
    updateTargetAudience.set(targetAudienceFilter);
    removeTrigger.remove();
  } catch (err) {
    console.error("ERROR", err);
  }
};
