import { FavouriteProduct } from "../Components/Product/FavouriteProduct";
import { merchantMap } from "../util/filtersMap";

export const FavouritesPage: view = ({
  groupedFavouriteProducts = observe.groupedFavouriteProductIds,
}) => {
  if (!groupedFavouriteProducts) return null;

  const merchantIds: any[] = Object.keys(groupedFavouriteProducts);

  return (
    <main className="site-main  main-container no-sidebar">
      <div className="container">
        <div className="row">
          <div className="main-content col-md-12">
            <div className="page-main-content">
              <div id="yith-wcwl-form" className="akasha">
                {/* <!-- TITLE -->
                        <!-- WISHLIST TABLE --> */}
                <table
                  className="shop_table cart wishlist_table"
                  data-pagination="no"
                  data-per-page="5"
                  data-page="1"
                  data-id=""
                  data-token=""
                >
                  <thead>
                    <tr>
                      <th className="product-remove"></th>
                      <th className="product-thumbnail"></th>
                      <th className="product-name">
                        <span className="nobr">Product Name</span>
                      </th>
                      <th className="product-price">
                        <span className="nobr">Unit Price </span>
                      </th>
                      <th className="product-add-to-cart"></th>
                    </tr>
                  </thead>
                  {!merchantIds.length && (
                    <tbody>
                      <tr><th colSpan={6}>Nu ai adaugat niciun produs la favorite.</th></tr>
                    </tbody>
                  )}
                  {!!merchantIds.length && merchantIds.map((merchantId) => {
                    return (
                      <tbody key={merchantId}>
                        <tr><th colSpan={6}>Produse vandute prin {merchantMap[merchantId].name}</th></tr>
                        {Object.keys(groupedFavouriteProducts[merchantId]).map(
                          (productId: string) => {
                            return (
                              <FavouriteProduct
                                productId={productId}
                                key={productId}
                              />
                            );
                          }
                        )}
                      </tbody>
                    );
                  })}
                  <tfoot>
                    <tr>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
