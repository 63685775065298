import { ProductsListLink } from "./ProductsListLink";

export const DeleteFiltesBtn = () => {
  return (
    <button>
      <ProductsListLink
        title="Șterge Filtrele"
        params={{ categories: [], targetAudience: ["any"], merchant: [], size: [] }}
      ></ProductsListLink>
    </button>
  );
};
