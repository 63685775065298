import { Tooltip } from "react-tooltip";
import { ProductsListLink } from "./ProductsListLink";

export const SizeFilter: view = ({
  size = observe.ui.filters.size,
  selectedSizes = observe.queryParamObject.size
}) => {

  const selectedSizesMap = (selectedSizes || []).reduce((s, k) => {
    s[k] = true;
    return s;
  }, {} as any)

  const isSelected = (key) => selectedSizesMap[key];
  const activeFilters = (k: string) => {
    if(!isSelected(k)) return k
    return null
  }

  return (
    <div
      id="akasha_layered_nav-6"
      className="widget akasha widget_layered_nav akasha-widget-layered-nav"
    >
      <h2 className="widgettitle">
        Mărimi<span className="arrow"></span>
      </h2>
      <ul className="akasha-widget-layered-nav-list custom-radio-buttons custom-size">
        {Object.values(size || {}).map((sizeObj: any) => {
          return (
            <li
              data-tip={sizeObj.name === "OSFA" ? "One-size-fits-all" : ""}
              key={sizeObj.key}
              className={`akasha-widget-layered-nav-list__item akasha-layered-nav-term ${
                isSelected(sizeObj.key) && "custom-radio-active"
              }`}
            >
              <ProductsListLink style={{ width: "auto" }} title={sizeObj?.name} params={{size: activeFilters(sizeObj.key)}} ></ProductsListLink>
            </li>
          );
        })}
        <Tooltip place="top" />
      </ul>
    </div>
  );
};
