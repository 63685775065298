import { BreadCrumbs } from "../Components/Product/BreadCrumbs";
import { ProductWide } from "../Components/Product/ProductWide";

export const ProductsDetailsPage: view = ({
  productId = observe.queryParamObject.productId,
}) => {
  return (
    <div className="single single-product">
      <BreadCrumbs productId={productId} />
      <div className="main-container shop-page no-sidebar">
        <div className="container">
          <div className="row">
            <div className="main-content col-md-12">
              <div
                id={`product-` + productId}
                className="post-27 product type-product status-publish has-post-thumbnail product_cat-table product_cat-new-arrivals product_cat-lamp product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-variable has-default-attributes"
              >
                <div className="main-contain-summary">
                  <ProductWide productId={productId} />

                  {/* <ShareSocials productId={productId} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
