import { PageLink } from "../../lib/router/link";
import { ProductsListLink } from "./ProductsListLink";

export const ShopFilter: view = ({
  merchants = observe.ui.filters.merchant,
  selectedMerchants = observe.queryParamObject.merchant
}) => {
  selectedMerchants = selectedMerchants || [];
  const selectedMerchantsMap = selectedMerchants.reduce((s, k) => {
    s[k] = true;
    return s;
  }, {} as any);

  const isSelected = (key) => selectedMerchantsMap[key];
  const activeMerchants = (k: string) => {
    if(!isSelected(k)) return [...selectedMerchants, k].join(",")
    return selectedMerchants.filter((m:string) => m != k).join(",")
  }

  return (
    <div
      id="akasha_product_categories-3"
      className="widget akasha widget_product_categories"
    >
      <h2 className="widgettitle">
        Magazin<span className="arrow"></span>
      </h2>

      <ul className="product-categories">
        {Object.keys(merchants || {}).map((k) => {
          const merchantObj = merchants[k];

          return (
            <li
              key={k}
              className={`checkbox-item  ${
                isSelected(k) && "checbox-item-active"
              }`}
            >
              <ProductsListLink style={{ width: "auto" }} title={merchantObj?.name} params={{merchant: activeMerchants(k)}} ></ProductsListLink>
              {/* <span className="count">({s.noOfProduct})</span> */}
            </li>
          );
        })}
      </ul>
    </div>
    // <div
    //   id="akasha_product_categories-3"
    //   className="widget akasha widget_product_categories"
    // >
    //   <h2 className="widgettitle">
    //     Magazin<span className="arrow"></span>
    //   </h2>

    //   <ul className="product-categories">
    //     {Object.keys(merchants || {}).map((k) => {
    //       const merchantObj = merchants[k];

    //       return (
    //         <li
    //           key={merchantObj.id}
    //           onClick={() =>
    //             selectMerchants.set(!!!merchantObj.selected, { id: k })
    //           }
    //           className={`cat-item cat-item-22  ${
    //             merchantObj.selected && "cat-item-active"
    //           }`}
    //         >
    //           <a>{merchantObj?.name}</a>
    //           <span className="count">({s.noOfProduct})</span>
    //         </li>
    //       );
    //     })}
    //   </ul>
    // </div>
  );
};
