export const SortBy: view = ({
  sortBy = observe.ui.sortBy,
  updateSortBy = update.ui.sortBy,
}) => {
  return (
    <form className="akasha-ordering">
      <select
        defaultValue={sortBy || sortBy === "newest"}
        title="product_cat"
        name="orderby"
        className="orderby"
        onChange={(e) => updateSortBy.set(e.target.value)}
      >
        <option value="newest">Recomandate</option>
        <option value="price-asc">Preț crescător</option>
        <option value="price-desc">Preț descrescător</option>
      </select>
    </form>
  );
};
