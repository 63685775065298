import { filtersOfTypeSelect, filtersOfTypeValue } from "src/util/filterType";

export const addSortAndFilterToQuery: producer = async ({
  filters = observe.ui.filters,
  sortBy = observe.ui.sortBy,
  updateQueryParam = update.queryParamObject,
  updatePageNumber = update.ui.pageNumber,
}) => {
  if (!filters && !sortBy) return;
  const queryObj: any = {};

  if (filters) {
    Object.keys(filters).forEach((filterId) => {
      const filterObj = filters[filterId];
      let queryValues: any = [];

      if (filtersOfTypeSelect.includes(filterId)) {
        Object.keys(filterObj).forEach(
          (filterOptionId) =>
            filterObj[filterOptionId].selected &&
            queryValues.push(filterOptionId)
        );
      }
      if (filtersOfTypeValue.includes(filterId) && filterObj) {
        queryValues = [filterObj];
      }

      if (filterId === "search" && filterObj) {
        queryValues = encodeURIComponent(filterObj);
      }
      if (queryValues?.length > 0) {
        queryObj[filterId] = queryValues;
      }
    });
  }
  // console.log(queryObj);
  if (sortBy) {
    queryObj["sortBy"] = sortBy;
  }

  updatePageNumber.set(0);
  updateQueryParam.merge(queryObj);
};
