import { router } from "./routes";

const onClick = (e: MouseEvent) => {
  // Fallback for browser that don't support the history API
  if (!("pushState" in window.history)) return true;

  // Ensure middle, control and command clicks act normally
  if (e.which == 2 || e.metaKey || e.ctrlKey) {
    return true;
  }
  const elem = e.currentTarget;
  e.preventDefault();
  window.history.pushState(
    null,
    elem.getAttribute("title"),
    "#"+elem.getAttribute("id")
  );
  // Do something awesome, then change the URL
  window.history.pushState(
    null,
    elem.getAttribute("title"),
    elem.getAttribute("href")
  );
  router.update();
  return false;
};

export const Link = (props:any) => {
  return <a {...props} onClick={onClick}>{props.children}</a>;
};


export const PageLink = (props:any) => {
  const href = router.getLink(props.page, props.params);
  return (<Link
    {...props}
    href={href}
  ></Link>)
}