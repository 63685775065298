import { priceFilterLimits, priceMaxValue } from "src/util/filterType";
export const PriceFilter: view = ({
  priceGte = observe.ui.filters.priceGte,
  updatePriceGte = update.ui.filters.priceGte,
  priceLte = observe.ui.filters.priceLte,
  updatePriceLte = update.ui.filters.priceLte,
  priceFilterOption = observe.ui.filters.priceFilterOption,
  updatePriceFilterOption = update.ui.filters.priceFilterOption,
}) => {
  return (
    <div
      id="akasha_price_filter-2"
      className="widget akasha widget_price_filter"
    >
      <h2 className="widgettitle">
        Filtrează preț<span className="arrow"></span>
      </h2>
      <ul className="price-filter product-categories">
        {priceFilterLimits.map((interval, index) => (
          <li
            key={index}
            onClick={() => {
              if (priceFilterOption == index + 1) {
                updatePriceFilterOption.remove();
                updatePriceGte.remove();
                updatePriceLte.remove();
              } else {
                updatePriceFilterOption.set(index + 1);
                updatePriceGte.set(interval[0]);
                updatePriceLte.set(interval[1]);
              }
            }}
            className={`radio-item ${
              priceFilterOption == index + 1 && "radio-item-active"
            }`}
          >
            {<a>{`${interval[0]} - ${interval[1]}`}</a>}
          </li>
        ))}
        <li>
          <div
            onClick={() => {
              if (priceFilterOption == 4) {
                updatePriceFilterOption.remove();
                updatePriceGte.remove();
                updatePriceLte.remove();
              } else {
                updatePriceFilterOption.set(4);
              }
            }}
            className={`radio-item ${
              priceFilterOption == 4 && "radio-item-active"
            }`}
          >
            <a>Interval preț</a>
          </div>
          {priceFilterOption == 4 && (
            <div className="range-input">
              <input
                type="number"
                defaultValue={priceGte}
                onChange={(e) => {
                  updatePriceGte.set(e.target.value);
                }}
              />
              <span>-</span>
              <input
                type="number"
                defaultValue={priceLte}
                onChange={(e) => {
                  updatePriceLte.set(e.target.value);
                }}
              />
            </div>
          )}
        </li>
      </ul>
      {/* <Label /> */}
    </div>
  );
};
const Label: view = ({
  priceGte = observe.ui.filters.priceGte,
  priceLte = observe.ui.filters.priceLte,
}) => {
  return (
    <form method="get" action="#">
      <div className="price_slider_wrapper">
        <div className="price_slider_amount">
          <div className="price_label">
            Price: <span className="from">{priceGte || 0} lei</span> —
            <span className="to">{priceLte || priceMaxValue} lei</span>
          </div>
        </div>
      </div>
    </form>
  );
};
