import { ClipLoader } from "react-spinners";

export const TryAgainBtn: view = ({
  errorGettingProducts = observe.ui.errorGettingProducts,
  updateTriggerGetProducts = update.triggers.getProducts.type,
  gettingProducts = observe.ui.gettingProducts,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      {gettingProducts ? (
        <div>
          <ClipLoader size={30} color="#1fa76e" speedMultiplier={0.6} />
        </div>
      ) : (
        <button
          className="secondary"
          onClick={() => {
            updateTriggerGetProducts.set(errorGettingProducts);
          }}
        >
          Încearca din nou
        </button>
      )}
    </div>
  );
};
