import { Tooltip } from "react-tooltip";
export const ColorFilter: view = ({
  colours = observe.ui.filters.colour,
  selectColor = update.ui.filters.colour[param.id].selected,
}) => {
  return (
    <div
      id="akasha_akasha_layered_nav-4"
      className="widget akasha_widget_layered_nav widget_layered_nav"
    >
      <h2 className="widgettitle">
        Culori<span className="arrow"></span>
      </h2>
      <div className="color-group">
        {Object.keys(colours || {}).map((k) => {
          const colorObj = colours[k];
          return (
            <div key={colorObj.name}>
              <a
                data-tip={colorObj.name}
                key={colorObj.name}
                className={`term-color  ${colorObj.selected && "selected"} ${
                  colorObj.light && "white"
                }`}
                onClick={(e: any) => {
                  e.preventDefault();
                  selectColor.set(!!!colorObj.selected, { id: k });
                }}
              >
                <i
                  style={{
                    border: colorObj.id === "Alb" ? "1px solid #e4e4e4" : "",
                    color: colorObj.value,
                  }}
                ></i>
              </a>
              <Tooltip place="top" />
            </div>
            // <a className="term-color">
            //   <i className="multicolor">
            //     <span></span>
            //     <span></span>
            //     <span></span>
            //     <span></span>
            //   </i>
            // </a>
          );
        })}
      </div>
    </div>
  );
};
