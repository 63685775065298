import { Product } from "../types/product";
import { merchantMap } from "../util/filtersMap";

export const BuyButton = ({ product }: { product: Product }) => {
  if(!product) return null;
  return (
    <div className="variations_form cart">
      <button
        type="submit"
        className="single_add_to_cart_button button alt"
        onClick={() => {
          window.open(product.redirectTo);
          return false;
        }}
        title={`Cumpără de la ${merchantMap[product.merchant].name}`}
      >
        Cumpără de la {merchantMap[product.merchant].name}
      </button>
    </div>
  );
};
