import axios from "axios";
import { baseUrl } from "src/config";
import { Product } from "../types/product";
import { defaultProductsPerPage } from "../util/constants";
import { debounce } from "../util/debounce";

const loadProductsDebounced = debounce((params: string, cb: Function) => {
  const result = axios.get(`${baseUrl}/products?${params}`);
  cb(result);
}, 2000);

export const updateCurrentProductsList: producer = ({
  filterId = observe.currentSearchFilter.id,
  productIdsByFilter = observe.productIdsByFilter,
  filteredProductIds = update.filteredProductIds,
}) => {
  if (!filterId || !productIdsByFilter) return;
  const productIdsCollection = productIdsByFilter[filterId] || {};
  // console.log("updateCurrentProductsList", filterId, productIdsByFilter);
  filteredProductIds.set(productIdsCollection);
};

export const updateCurrentFiler: producer = ({
  triggeredQueryParamObject = observe.queryParamObject,
  setCurrentSearchFilter = update.currentSearchFilter,
}) => {
  if (!triggeredQueryParamObject) return;

  //cleanup falsy values
  const queryParamObject = Object.keys(triggeredQueryParamObject).reduce(
    (s, k) => {
      if (
        triggeredQueryParamObject[k] &&
        triggeredQueryParamObject[k][0] !== "any"
      )
        s[k] = triggeredQueryParamObject[k];
      return s;
    },
    {} as any
  );

  const idparams = { ...queryParamObject };
  delete idparams.pageNumber;
  const searchId = JSON.stringify(idparams);

  setCurrentSearchFilter.set({
    criteria: queryParamObject,
    id: searchId,
  });
};

export const getProducts: producer = async ({
  currentSearchFilter = observe.currentSearchFilter,
  updateByID = update.productsById,

  productIdsByFilter = get.productIdsByFilter,
  updateProductIdsByFilter = update.productIdsByFilter[param.id],
  pageNumber = 0,
}) => {
  // console.log("getProducts", currentSearchFilter);
  if (!currentSearchFilter) return;

  const searchId = currentSearchFilter.id;
  const ids = productIdsByFilter.value()?.[searchId]?.items || [];

  updateProductIdsByFilter.merge({
    busy: true,
  }, currentSearchFilter);

  const urlParams = new URLSearchParams(currentSearchFilter.criteria);
  urlParams.sort();
  const params = urlParams.toString();

  loadProductsDebounced(params, async (result: Promise<any>) => {
    try {
      const response = await result;
      const products = response.data;

      const filteredIds = products.map((p: Product) => p._id);
      const byIdMap = products.reduce(
        (s: { [s: string]: Product }, p: Product) => {
          s[p._id] = p;
          return s;
        },
        {} as { [s: string]: Product }
      );

      updateByID.merge(byIdMap);

      let hasMore = true;
      if (products.length < defaultProductsPerPage) {
        hasMore = false;
      }
      if (pageNumber == 1) {
        updateProductIdsByFilter.set({
          items: filteredIds,
          busy: false,
          hasMore,
        }, currentSearchFilter);
      } else {
        updateProductIdsByFilter.set({
          items: [...ids, ...filteredIds],
          busy: false,
          hasMore,
        }, currentSearchFilter);
      }
    } catch (err) {
      updateProductIdsByFilter.merge({
        busy: false,
        error: "Could not load batch.",
      }, currentSearchFilter);
      console.error("ERROR", err);
    }
  });
};
