export const saveFavsToLocalStorage: producer = async ({
  favouriteProductIds = observe.favouriteProductIds,
}) => {
  if (!favouriteProductIds) return;
  try {
    localStorage.setItem("favs", JSON.stringify(favouriteProductIds));
  } catch (e) {}
};

export const loadFavsToLocalStorage: producer = async ({
  updateFavouriteProductIds = update.favouriteProductIds,
}) => {
  try {
    const favouriteProductIds = JSON.parse(
      localStorage.getItem("favs") || "{}"
    );
    updateFavouriteProductIds.set(favouriteProductIds);
  } catch (e) {}
};
