import { PageLink } from "../../lib/router/link";

export const ProductsListLink:view = ({
    title,
    params,
    style,
    queryParams = observe.queryParamObject
}) => {
  const combinedParams = {...queryParams, ...params};

    if(!combinedParams.targetAudience) combinedParams.targetAudience = "any";
    // if(!queryParams.categories) queryParams.categories = "all";
    // if(combinedParams.categories) combinedParams.categories = combinedParams.categories.sort().map(encodeURIComponent).join(",")

    // console.log(params, queryParams, combinedParams);
    return (<PageLink style={style} page="ProductsList" params={combinedParams} title={title} >
    {title}
  </PageLink>)
}